@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
@import url(https://fonts.googleapis.com/css?family=Scada:400,700);
@import url(https://fonts.googleapis.com/css?family=Russo+One);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

a {
  text-decoration: none; }

ul {
  list-style: none; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  justify-content: flex-start;
  min-height: 100vh;
  min-width: 320px; }

.section.head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto; }
  .section.head .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    background: #00e6ff;
    background-image: url(/static/media/grey-jean.6ce2eee1.png);
    height: 133px;
    padding: 0 0 0 34px; }
  .section.head .logo-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 auto;
    max-width: 1170px; }
  .section.head .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    align-items: baseline;
    margin: 0;
    padding: 0; }
  .section.head .nav-bar-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 8px;
    color: black;
    margin: 0 0 0 10px; }
  .section.head #code-lang-skills {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    color: black;
    padding: 0 0 0 5px;
    font-weight: 100;
    font-size: 18px; }
  .section.head #code-lang-skills:hover {
    background: rgba(0, 0, 255, 0.1);
    cursor: pointer;
    color: #6e06d6;
    font-size: 19px;
    -webkit-transition: font-size 0.3s;
    transition: font-size 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }
  .section.head .logo {
    width: 90px;
    height: auto;
    margin: 0 0 10px 0; }
  .section.head h2 {
    color: #6e06d6;
    margin: 0 0 0 52px;
    font-size: 14px; }

#contact-link {
  display: none; }

.nav-bar {
  background: #ab63fe;
  height: 40px; }
  .nav-bar .nav-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    max-width: 1170px;
    height: 40px;
    margin: 0 35px;
    padding: 3px 0 0;
    align-items: center; }
    .nav-bar .nav-list .nav-item {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 auto;
      justify-content: center;
      height: 30px;
      padding: 0 5px;
      border-bottom: solid 4px transparent; }
      .nav-bar .nav-list .nav-item#home-link {
        width: 48px; }
      .nav-bar .nav-list .nav-item#portfolio-link {
        width: 78px; }
      .nav-bar .nav-list .nav-item#skills-link {
        width: 49px; }
      .nav-bar .nav-list .nav-item#contact-link {
        width: 69px; }
    .nav-bar .nav-list .nav-item:hover {
      color: #6e06d6;
      font-weight: bold; }

#skills-paragraph {
  display: none; }

.section.body-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  flex: 1 0 auto; }

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  flex: 1 0 auto;
  justify-content: flex-start;
  background: white;
  padding: 45px 0 0 0; }
  .container .phrases {
    margin: 0 0 30px;
    padding: 0 40px;
    display: flex;
    align-self: center;
    height: 80px; }
    .container .phrases .large-text {
      margin: 30px 0 0;
      text-align: center;
      font-size: 35px; }
    .container .phrases .small-text {
      margin: 30px 0 0;
      font-size: 20px;
      text-align: center; }
  .container .row-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    align-items: center;
    margin: 0 0 30px; }
  .container .paragraph {
    padding: 0 40px;
    margin: 0 0 20px; }
    .container .paragraph p {
      margin: 0 0 10px 0; }
  .container .head-shot {
    align-self: center;
    margin: 0 0 40px; }
    .container .head-shot img {
      height: 120px;
      width: 120px;
      margin: 0;
      border: solid 1px #8b6e89;
      border-radius: 100px; }
  .container .learn-more-link {
    align-self: center;
    color: #555;
    font-weight: bold; }

.footer {
  background: #e2e2e2;
  width: 100%; }
  .footer .footer-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    padding: 10px 40px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%; }
    .footer .footer-list .footer-item {
      margin: 0 10px 3px;
      font-size: 12px;
      letter-spacing: 0.05em; }
    .footer .footer-list .footer-item:hover {
      color: #8b6e89;
      font-weight: bold; }

#contact-page h1 {
  padding: 0 40px;
  margin: 0 0 45px; }

#contact-page .contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  padding: 0 40px; }
  #contact-page .contacts span {
    margin: 0 0 30px;
    font-size: 15px; }
    #contact-page .contacts span i {
      color: #6e06d6;
      font-size: 25px; }
    #contact-page .contacts span i:hover {
      color: #444; }
    #contact-page .contacts span a {
      color: #555; }
    #contact-page .contacts span a:hover {
      color: #444; }

#skills-page .language {
  padding: 0; }

#skills-page h1 {
  display: flex;
  margin: 0 0 30px; }

#portfolio .portfolio-links {
  display: flex;
  flex-direction: column;
  align-items: center; }

#portfolio .game-link {
  margin: 0 0 40px; }
  #portfolio .game-link img {
    width: 150px;
    height: auto; }
  #portfolio .game-link p {
    font-size: 12px; }

#portfolio .repository-link a {
  color: black;
  font-size: 120px; }

#portfolio .repository-link p {
  font-size: 12px; }

#portfolio .language {
  padding: 0; }

#portfolio h1 {
  display: flex;
  padding: 0 40px;
  margin: 0 0 30px; }

body {
  font-family: "Scada", sans-serif;
  letter-spacing: 2px;
  color: #555; }
  body p {
    line-height: 1.2em;
    font-size: 16px; }

h1 {
  font-size: 25px;
  font-weight: bold; }

h2 {
  font-size: 18px; }

li {
  font-size: 15px; }

.footer-item {
  color: #9e9a9a; }

@media screen and (min-width: 490px) {
  .section.head .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    padding: 0 40px 0 34px;
    justify-content: flex-start;
    align-items: center; }
  .section.head .logo-title {
    flex-direction: row;
    align-items: center; }
  .section.head .logo {
    flex: 0 1 auto;
    width: 120px;
    height: auto; }
  .section.head h2 {
    padding: 37px 0 0;
    margin: 0 0 0 10px;
    font-size: 18px; }
  .section.head .nav-bar-icons {
    margin: 0 0 0 10px; }
  .section.head #code-lang-skills {
    font-size: 20px;
    padding: 0 0 0 5px; }
  .section.head #code-lang-skills:hover {
    font-size: 21px; }
  #contact-link {
    display: flex; }
  #skills-paragraph {
    display: block; }
  .container .paragraph {
    margin: 0 0 30px; }
  .container .contact-page-link {
    display: none; }
  #contact-page .contacts span {
    margin: 0 0 35px;
    font-size: 18px; } }

@media screen and (min-width: 550px) {
  #navigation {
    justify-content: flex-start; }
    #navigation li {
      margin: 0 55px 0 0; } }

@media screen and (min-width: 768px) {
  .section.head .title {
    padding: 0 80px 0 74px; }
  #navigation {
    padding: 3px 77px 0;
    margin: auto; }
    #navigation li {
      font-size: 16px; }
  .container .paragraph {
    padding: 0 0 0 80px;
    margin: 0 0 40px; }
    .container .paragraph h1 {
      display: flex;
      margin: 0 0 50px; }
  .container .head-shot img {
    width: 150px;
    height: 150px; }
  #contact-page .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 auto;
    flex: 1 0 auto;
    justify-content: flex-start;
    padding: 45px 80px 0; }
  #contact-page .paragraph-head-contact {
    margin: 0;
    padding: 0; }
    #contact-page .paragraph-head-contact h1 {
      padding: 0; }
  #contact-page .contacts {
    margin: 0;
    padding: 0; }
  #skills-page .paragraph {
    padding: 0 80px; }
  #portfolio .paragraph {
    padding: 0 80px; }
  #portfolio .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 auto;
    flex: 1 0 auto;
    justify-content: flex-start; }
  #portfolio h1 {
    padding: 0 80px;
    margin: 0 0 45px; }
  #portfolio .portfolio-links {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 0 0 20px; }
  #portfolio .game-link {
    margin: 0; }
    #portfolio .game-link img {
      width: 120px; }
  #portfolio .repository-link {
    margin: 0 0 0 40px; }
    #portfolio .repository-link a {
      font-size: 100px; } }

@media screen and (min-width: 900px) {
  .section.head .title {
    height: 180px; }
  .section.head .logo {
    width: 150px; }
  .section.head h2 {
    font-size: 25px;
    margin: 0 0 0 25px;
    padding: 47px 0 0; }
  .section.head .nav-bar-icons {
    margin: 0 0 0 200px;
    font-size: 12px; }
  .section.head #code-lang-skills {
    padding: 0 0 0 10px;
    font-size: 50px; }
  .section.head #code-lang-skills:hover {
    font-size: 50.5px; }
  .section.body-content {
    padding: 0 80px;
    justify-content: flex-start; }
  .container.home-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 70px 0 0; }
    .container.home-container .phrases {
      margin: 0 0 50px;
      padding: 0 80px;
      justify-content: flex-end;
      align-self: inherit; }
      .container.home-container .phrases h1 {
        width: 450px; }
      .container.home-container .phrases .large-text {
        font-size: 50px; }
      .container.home-container .phrases .small-text {
        font-size: 30px; }
    .container.home-container .photo-home-nav {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .container.home-container .photo-home-nav img {
        border-radius: 100px;
        height: 50px;
        width: 50px;
        margin: 0 10px 0 0; }
      .container.home-container .photo-home-nav p {
        height: auto;
        width: 210px;
        font-size: 12px; }
      .container.home-container .photo-home-nav .learn-more-link-large {
        font-weight: bold;
        color: #6e06d6; }
    .container.home-container .learn-more-link {
      width: 0px;
      display: none; }
  #navigation li {
    margin: 0 75px 0 0; }
  #contact-page .container {
    padding: 75px 0 0;
    max-width: 1170px;
    margin: auto;
    flex: 1 0 auto; }
  #contact-page h1 {
    margin: 0 0 60px; }
  #contact-page .contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    padding: 0;
    max-width: 1100px; }
    #contact-page .contacts span {
      margin: 0 0 30px;
      font-size: 15px; }
  #portfolio .repository-link {
    margin: 0 0 0 80px; }
  #portfolio .container {
    max-width: 1170px;
    margin: auto; }
    #portfolio .container h1 {
      padding: 0; }
    #portfolio .container .paragraph {
      padding: 0;
      margin: 0 auto 20px; }
    #portfolio .container .portfolio-links {
      margin: 30px 0 0; }
  #skills-page .paragraph {
    margin: 0;
    padding: 0; }
  #skills-page .container {
    max-width: 1170px;
    margin: auto; } }

@media screen and (min-width: 1080px) {
  .section.head .title {
    padding: 0 120px 0 114px; }
  .section.head .logo-title {
    margin: auto;
    flex: 1 0 auto;
    justify-content: space-between; }
    .section.head .logo-title .heading {
      flex: 1 0 auto; }
  .section.head .nav-bar-icons {
    margin: 0 0 0 310px; }
  .section.head .nav-bar {
    display: flex; }
  #navigation {
    padding: 3px 117px 0;
    margin: auto;
    flex: 1 0 auto; }
    #navigation li {
      margin: 0 110px 0 0; }
  .section.body-content {
    padding: 0 120px;
    justify-content: flex-start; }
  .container .paragraph {
    padding: 0 120px;
    max-width: 1170px;
    margin: auto;
    justify-content: flex-start; }
    .container .paragraph .container.home-container .phrases {
      margin: 0 auto 10px; }
      .container .paragraph .container.home-container .phrases h1 {
        margin: 0; }
    .container .paragraph .container.home-container .photo-home-nav {
      margin: 0 auto 0; }
    .container .paragraph #contact-page h1 {
      padding: 0 120px;
      margin: 0 0 60px; }
    .container .paragraph #contact-page .contacts {
      padding: 0 120px; }
    .container .paragraph #contact-page .container {
      max-width: 1170px; }
    .container .paragraph #skills-page .paragraph {
      padding: 0 120px; }
    .container .paragraph #portfolio .container {
      margin: auto; }
    .container .paragraph #portfolio .paragraph {
      padding: 0 120px; }
    .container .paragraph #portfolio h1 {
      padding: 0 120px;
      margin: 0 0 60px; }
    .container .paragraph #portfolio .repository-link {
      margin: 0 0 0 120px; } }

@media screen and (min-width: 1300px) {
  .section.head .nav-bar-icons {
    margin: 0 50px 0 450px; }
  .container {
    padding: 60px 0 0; }
  .container.home-container .phrases {
    height: auto;
    width: auto;
    padding: 0; }
    .container.home-container .phrases h1 {
      width: 600px;
      height: 80px; }
    .container.home-container .phrases .large-text {
      font-size: 70px; }
    .container.home-container .phrases .small-text {
      font-size: 40px; }
  .container.home-container .photo-home-nav p {
    font-size: 15px;
    width: 300px; }
  .container.home-container .photo-home-nav img {
    width: 80px;
    height: 80px; } }

#game-canvas {
  display: block;
  margin: 20px 0 10px;
  width: 90vw;
  height: auto;
  border: 7px ridge #e6e6e6;
  max-width: 800px;
  max-height: 90%;
  font-size: 100%;
  background: black; }

#game-page {
  max-height: 100vh;
  overflow: hidden;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  #game-page button {
    border: silver ridge 3px;
    background: #e5e5e5;
    width: 200px;
    cursor: pointer;
    margin: 0 0 10px;
    font-size: 20px;
    font-family: "Russo One", sans-serif; }
  #game-page .game-menu {
    height: 99vh;
    width: 99vw;
    background: #010922;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: silver ridge 5px; }
    #game-page .game-menu h1 {
      text-align: center;
      color: #ffdf2a;
      text-shadow: black 2px 2px;
      margin: 0 15px 60px;
      font-size: 30px; }
    #game-page .game-menu h2 {
      text-align: center;
      color: #ffdf2a;
      text-shadow: black 2px 2px;
      margin: 0 15px 60px;
      font-size: 15px; }
    #game-page .game-menu .game-menu-links {
      padding: 0 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 20px; }
    #game-page .game-menu .game-specific {
      display: flex;
      flex-direction: column;
      align-items: center; }
  #game-page .score-table {
    height: 99vh;
    width: 99vw;
    background: #010922;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: silver ridge 5px;
    list-style: none; }
    #game-page .score-table h1 {
      text-align: center;
      color: #ffdf2a;
      text-shadow: black 2px 2px;
      margin: 0 15px 30px;
      font-size: 30px; }
    #game-page .score-table h2 {
      text-align: center;
      color: #d9fffe;
      margin: 0 0 10px; }
    #game-page .score-table ul {
      border: #555 ridge 7px;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      width: 80vw;
      min-width: 250px;
      max-width: 400px; }
  #game-page .table-entry {
    width: auto;
    color: black;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0;
    padding: 10px 5px; }
  #game-page .even {
    background: #a70b00;
    color: #fffc99; }
  #game-page .odd {
    background: #e5e5e5; }
  #game-page .player-name {
    width: 220px;
    font-weight: bold;
    font-size: 18px;
    margin: 0; }
  #game-page .player-score {
    display: flex;
    align-self: flex-end;
    margin: 0 5px 0 0;
    font-weight: normal; }
  #game-page .input-date {
    font-size: 8px;
    align-self: center; }
  #game-page .game-screen {
    height: 99vh;
    width: 99vw;
    background: #010922;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: silver ridge 5px;
    background: #010511;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden; }
    #game-page .game-screen .game-status-bar {
      margin: 10px 0 0;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      #game-page .game-screen .game-status-bar div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 40px; }
      #game-page .game-screen .game-status-bar h3 {
        margin: 0 10px 20px;
        padding: 5px;
        width: 120px;
        border: solid 1px silver;
        color: white; }
    #game-page .game-screen button {
      width: 80px;
      font-size: 12px;
      color: #a70101; }
  #game-page .player-form {
    height: 99vh;
    width: 99vw;
    background: #010922;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: silver ridge 5px;
    align-items: center; }
    #game-page .player-form h1 {
      color: #d9fffe;
      margin: 0 10px 20px; }
    #game-page .player-form h2 {
      color: #ffdf2a;
      margin: 0 10px 20px;
      text-align: center;
      font-size: 15px; }
    #game-page .player-form form {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around; }
    #game-page .player-form input {
      width: 250px;
      padding: 10px;
      margin: 0 15px;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; }
    #game-page .player-form button {
      font-size: 20px;
      height: 40px;
      width: 100px; }
  #game-page .control-screen {
    height: 99vh;
    width: 99vw;
    background: #010922;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: silver ridge 5px;
    align-items: center; }
    #game-page .control-screen h1 {
      text-align: center;
      color: #ffdf2a;
      text-shadow: black 2px 2px;
      margin: 0 15px 30px;
      font-size: 30px; }
    #game-page .control-screen ul {
      max-width: 500px;
      margin: 0 0 20px; }
    #game-page .control-screen li {
      display: flex;
      align-items: baseline;
      margin: 20px;
      color: #ffedd3;
      text-shadow: 2px 2px black; }
    #game-page .control-screen i {
      margin: 0 10px;
      font-size: 20px;
      color: #ff7f2a; }
  @media screen and (min-width: 490px) {
    #game-page .game-screen .game-status-bar {
      margin: 0;
      padding: 0 15px 15px; }
      #game-page .game-screen .game-status-bar div {
        flex-direction: row;
        justify-content: center; } }
  @media screen and (min-width: 600px) {
    #game-page .score-table .table-entry .player-score {
      font-weight: bold; }
    #game-page .score-table .table-entry .input-date {
      font-size: 12px; }
    #game-page .game-screen .game-status-bar {
      padding: 0 15px; }
      #game-page .game-screen .game-status-bar div {
        margin: 0 0 20px; }
      #game-page .game-screen .game-status-bar h3 {
        margin: 3px 10px; }
    #game-page #game-canvas {
      margin: auto; } }
  @media screen and (min-width: 768px) {
    #game-page .game-screen .game-status-bar {
      display: flex;
      flex-direction: row;
      align-items: baseline; }
      #game-page .game-screen .game-status-bar button {
        margin: 0 0 0 100px; } }

